<style scoped>
  
</style>

<template>
    <ButtonTopbar buttonText="Créer un RIB" @onTopbarButtonClicked="displayCreateRibModal">
        <template v-slot:iconBefore>
            <font-awesome-icon :icon="['fas', 'plus']" />
        </template>
    </ButtonTopbar>
    <Aggrid
        apiRoute="rib/list"
        :columnDefs="columnDefs" 
        :context="context"
        :frameworkComponents="frameworkComponents"
        aggridHeightSubstract="100px"
    />
        <Modal v-show="displayModalCreateOrEditRib === true">
        <template v-slot:modalIcon>
            <font-awesome-icon :icon="['fas', 'pen']" />
        </template>
        <template v-slot:modalTitle>
            <div v-show="createOrEditModeModalCreateOrEditRibInputChange === 'create'">CRÉER UN RIB</div>
            <div v-show="createOrEditModeModalCreateOrEditRibInputChange === 'edit'">MODIFIER UN RIB</div>
        </template>
        <template v-slot:modalContent>
            <form>
                <div>
                    <BaseInput 
                        v-model="rib.name"
                        label="Nom"
                        type="text"
                        name="name"
                        validator="text"
                        :error="formErrorsModalCreateOrEditRib?.name?.error?.message"
                        @onChange="onFormModalCreateOrEditRibInputChange"
                        :displayError="displayErrorModalCreateOrEditRib"
                    />
                </div>
                <div>
                    <BaseInput 
                        v-model="rib.value"
                        label="RIB"
                        type="textarea"
                        name="value"
                        validator="text"
                        :error="formErrorsModalCreateOrEditRib?.value?.error?.message"
                        @onChange="onFormModalCreateOrEditRibInputChange"
                        :displayError="displayErrorModalCreateOrEditRib"
                    />
                </div>
            </form>
        </template>
        <template v-slot:modalButtons>
            <BaseButton class="white-button" @click="closeCreateOrEditRibModal" buttonText="Fermer">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'times']" />
                </template>
            </BaseButton>
            <BaseButton class="orange-button" @click="submitCreateOrEditRibModal" buttonText="Valider">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'check']" />
                </template>
            </BaseButton>
        </template>
    </Modal>

</template>

<script>
    import Aggrid from '../../components/Aggrid/Aggrid';
    import ButtonTopbar from '../../components/Topbar/ButtonTopbar';
    import BaseInput from '../../components/Base/BaseInput.vue';
    import BaseButton from '../../components/Base/BaseButton.vue';
    import Modal from '../../components/App/Modal.vue';
    import ListRibsActionButtonsRenderer from './ListRibsActionButtonsRenderer.vue';
    import axios from 'axios'

    export default {
        name: 'ManualInvoice',
        components: {
            Aggrid,
            ButtonTopbar,
            BaseInput,
            BaseButton,
            Modal,
            ListRibsActionButtonsRenderer,

        },
        data() {
            return {
                columnDefs: [
                    { field: 'name', headerName : `Nom`, flex: 1, lockPosition: true },
                    { field: 'value', headerName : `RIB`, flex: 3, lockPosition: true },
                    { flex: 1, lockPosition: true, cellRenderer: 'ListRibsActionButtonsRenderer', suppressMenu: true}
                ],
                frameworkComponents: {
                    ListRibsActionButtonsRenderer: ListRibsActionButtonsRenderer
                },
                context: null,
                createOrEditModeModalCreateOrEditRibInputChange: null,
                displayModalCreateOrEditRib: false,
                rib: null,
                displayErrorModalCreateOrEditRib: false,
                formErrorsModalCreateOrEditRib: {}
            }
        },
        beforeMount() {
            this.context = {
                componentParent: this
            };

            this.resetRib();
        },
        mounted() {
            if (this.$route?.query?.openCreationModal) {
                this.displayCreateRibModal();
            }
        },
        methods: {
            displayCreateRibModal() {
                this.createOrEditModeModalCreateOrEditRibInputChange = 'create';
                this.resetRib();
                this.displayModalCreateOrEditRib = true;
            },
            displayEditRibModal(rib) {
                this.createOrEditModeModalCreateOrEditRibInputChange = 'edit';

                axios
                .get(`rib/${rib.id}`, { 
                    toastError: true, 
                    showPreloader: true 
                })
                .then((result) => {
                    this.rib = result.data;
                    this.displayModalCreateOrEditRib = true;
                })
                .catch(() => {});
            },
            resetRib() {
                this.rib = {
                    name: null,
                    value: null
                };
            },
            closeCreateOrEditRibModal() {
                this.resetRib();
                this.displayModalCreateOrEditRib = false;
                this.displayErrorModalCreateOrEditRib = false;
            },
            submitCreateOrEditRibModal() {
                if (this.createOrEditModeModalCreateOrEditRibInputChange === 'create') {
                    this.createRib();
                } else if (this.createOrEditModeModalCreateOrEditRibInputChange === 'edit') {
                    this.editRib();
                }
            },
            onFormModalCreateOrEditRibInputChange(input) {
                if (input.error?.message !== null) {
                    this.formErrorsModalCreateOrEditRib[input.name] = input;
                } else {
                    delete this.formErrorsModalCreateOrEditRib[input.name];
                }
            },
            createRib() {
                if (!this.checkFormModalCreateOrEditRib()) return;

                axios
                .post('rib/create', this.rib, { 
                    toastSuccessMessage: `RIB créé`, 
                    toastError: true, 
                    showPreloader: true 
                })
                .then(() => {
                    this.emitter.emit('ag-grid-reload')
                    this.closeCreateOrEditRibModal();
                })
                .catch(() => {});
            },
            editRib() {
                if (!this.checkFormModalCreateOrEditRib()) return;

                axios
                .put('rib/update', this.rib, { 
                    toastSuccessMessage: `Modification effectuée`, 
                    toastError: true, 
                    showPreloader: true 
                })
                .then(() => {
                    this.emitter.emit('ag-grid-reload')
                    this.closeCreateOrEditRibModal();
                })
                .catch(() => {});
            },
            checkFormModalCreateOrEditRib() {
                this.displayErrorModalCreateOrEditRib = true;

                return Object.keys(this.formErrorsModalCreateOrEditRib).length === 0;
            },
            deleteRib(manualInvoiceId) {
                axios
                .put("rib/delete/" + manualInvoiceId, null, {
                    toastSuccessMessage: `Suppression effectuée`,
                    toastError: true,
                    showPreloader: true,
                })
                .then(() => {
                    this.emitter.emit("ag-grid-reload");
                });
            }
        }
    }
</script>